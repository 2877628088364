button:global(.btn.btn-link).linkButton {
  padding: 10px 4px 10px 2px;
  height: 40px;
}

button:global(.btn.btn-link).linkButton:hover {
  background-color: var(--white);
  border: 1px solid var(--white);
}

button:global(.btn.btn-link).linkButton:focus {
  background-color: var(--white);
  border: 1px solid var(--white);
  outline: none;
}

.buttonWrapper {
  padding-top: 8px;
  border-top: 1px solid var(--alloy);
  position: sticky;
  bottom: 0;
  background-color: var(--white);
}
