.transitCalendarRow {
  flex: 1 1 auto;
}

.transitCalendarRowContext {
  flex: 0 1 15px;
  padding-top: 13px;
}

.transitCalendarRowScrollbar {
  flex: 0 1 14px;
}

.selectTransitSchedule {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
}

.rowHeight {
  height: 37px;
}
